import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
require("firebase/auth");

const firebaseConfig = {
    apiKey: "AIzaSyC2NauEdI69SbluhlTY51I5Zf4rXVGGGO4",
    authDomain: "themunchingchef-14.firebaseapp.com",
    databaseURL: "https://themunchingchef-14.firebaseio.com",
    projectId: "themunchingchef-14",
    storageBucket: "themunchingchef-14.appspot.com",
    messagingSenderId: "630323369694",
    appId: "1:630323369694:web:ec7975d576f51c9d80544f",
    measurementId: "G-W9KGH7T0ZT",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
