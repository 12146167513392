import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { resetState, getAdminStatus } from "../actions/userActions";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Material-UI Styles
const useStyles = (theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignInScreen extends React.Component {
    componentDidMount() {
        this.props.resetState();
    }

    // Configure FirebaseUI.
    uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                var user = authResult;
                //var credential = authResult.credential;
                var isNewUser = authResult.additionalUserInfo.isNewUser;
                //var providerId = authResult.additionalUserInfo.providerId;
                //var operationType = authResult.operationType;

                console.log("newUser", isNewUser);
                this.props.getAdminStatus(user.user.uid);

                if (isNewUser) {
                    var userInfo = user.additionalUserInfo.profile;

                    // add google account data if new yser
                    firebase
                        .firestore()
                        .collection("users")
                        .doc(user.user.uid)
                        .set({
                            email: userInfo.email,
                            firstname: userInfo.given_name,
                            lastname: userInfo.family_name,
                            fullname: userInfo.name,
                            username: "",
                            createdAt: new Date(),
                        });
                }

                return true;
            },
        },

        // Popup signin flow rather than redirect flow.
        signInFlow: "popup",

        // Display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    };

    render() {
        const { classes } = this.props;

        if (this.props.auth.uid) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Grid
                    container
                    justify="flex-start"
                    direction="column"
                    alignItems="center"
                    style={{ minHeight: "80vh", paddingTop: "60px" }}
                >
                    <div>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" align="center">
                            Login
                        </Typography>
                    </div>
                    <StyledFirebaseAuth
                        uiConfig={this.uiConfig}
                        firebaseAuth={firebase.auth()}
                    />
                    <Grid
                        style={{ paddingTop: "10px" }}
                        container
                        direction="column"
                        alignItems="center"
                    >
                        <Link
                            to="/password/reset"
                            variant="body2"
                            style={{ paddingBottom: "10px" }}
                        >
                            Forgot password?
                        </Link>
                        <Link to="/signup" variant="body2">
                            Don't have an account? Sign up here.
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        error: state.user.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState()),
        getAdminStatus: (uid) => dispatch(getAdminStatus(uid)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(SignInScreen));
