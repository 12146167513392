import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Material UI
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormDialog from "./formDialog";
import LinearProgress from "@material-ui/core/LinearProgress";

import { addUsername } from "../actions/userActions";

const useStyles = (theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
});

class ProfilePage extends React.Component {
    render() {
        if (this.props.userAuth.isEmpty && this.props.userAuth.isLoaded) {
            return <Redirect to="/login" />;
        }

        if (this.props.userAuth.isEmpty) {
            return (
                <React.Fragment>
                    <LinearProgress color="secondary" />
                </React.Fragment>
            );
        }

        const { classes } = this.props;

        return (
            <React.Fragment>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                        >
                            Profile Page
                        </Typography>
                    </Container>
                </div>
                <div style={{ minHeight: "60vh", paddingTop: "20px" }}>
                    <Typography variant="h4" align="center">
                        Hi, {this.props.userData.fullname}
                    </Typography>
                    <Grid container justify="center">
                        <List style={{ width: "60%" }}>
                            <ListItem>
                                <ListItemText
                                    primary="Email"
                                    secondary={this.props.userData.email}
                                    align="center"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="First Name"
                                    secondary={this.props.userData.firstname}
                                    align="center"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Last Name"
                                    secondary={this.props.userData.lastname}
                                    align="center"
                                />
                            </ListItem>
                            {this.props.userData.username !== "" ? (
                                <ListItem>
                                    <ListItemText
                                        primary="Username"
                                        secondary={this.props.userData.username}
                                        align="center"
                                    />
                                </ListItem>
                            ) : (
                                <Grid container justify="center">
                                    <FormDialog
                                        uid={this.props.uid}
                                        addUsername={this.props.addUsername}
                                    />
                                </Grid>
                            )}
                        </List>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.firebase.auth.uid,
        userData: state.firebase.profile,
        userAuth: state.firebase.auth,
    };
};

export default connect(mapStateToProps, { addUsername })(
    withStyles(useStyles)(ProfilePage)
);
