import React from "react";

import RecipeCard from "../recipes/RecipeCard";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const PopularRecipes = ({ recipes }) => {
    let currentList = recipes;

    if (currentList && currentList.length > 3) {
        currentList = currentList
            .sort((a, b) => b.likeCount - a.likeCount)
            .slice(0, 3);
    }

    return (
        <div>
            {currentList ? (
                <div>
                    <Grid container justify="center" style={{ paddingTop: 24 }}>
                        <Typography
                            variant="h3"
                            component="span"
                            align="center"
                            gutterBottom
                        >
                            Most Liked Recipes!
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: 24,
                            width: "100%",
                        }}
                        alignItems="stretch"
                        justify="center"
                        display="flex"
                    >
                        {currentList.map((currentRecipe) => (
                            <Grid
                                key={currentRecipe.id}
                                item
                                component="Card"
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                            >
                                <RecipeCard recipe={currentRecipe} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ) : null}
        </div>
    );
};

export default PopularRecipes;
