import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        marginTop: "20px",
    },
    media: {
        maxWidth: "60%",
    },
}));

const About = () => {
    const classes = useStyles();

    return (
        <div>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Card className={classes.card}>
                        <Typography
                            variant="h1"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            About Me
                        </Typography>

                        <CardContent>
                            <Grid
                                container
                                alignContent="center"
                                direction="column"
                                style={{ padding: 20 }}
                            >
                                <Typography align="center" variant="h5">
                                    Hi, I'm Chris!
                                </Typography>
                                <Typography align="justify" variant="body1">
                                    <p>
                                        I love cooking and enjoying good food.
                                        My aim is to share my love and passion
                                        for food and coffee with all of you, so
                                        do subscribe and stay tuned for more!
                                    </p>
                                </Typography>
                                <Grid container justify="center">
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        alt={"The Munching Chef"}
                                        image={
                                            "https://i.imgur.com/AeSz6DH.jpg"
                                        }
                                    />
                                </Grid>
                                <Typography align="justify" variant="body1">
                                    <p>
                                        Since you've clicked on this, perhaps
                                        you are wondering why or how I started
                                        cooking.
                                    </p>
                                    <p>
                                        I've been cooking since I was 11,
                                        starting from cooking special family
                                        meals for Christmas, preparing food to
                                        share on Chinese New Year, and the list
                                        goes on. Perhaps the first inspiration
                                        was to cook the own food I see and love
                                        outside at home! I created The Munching
                                        Chef, to share my passion and love for
                                        food and coffee, and hope to spread the
                                        food (love) around too!
                                    </p>
                                    <p>
                                        The Munching Chef was also conned,
                                        perhaps in cheek as I do like to
                                        taste(munch) all my foods as I'm making
                                        them! Hence, I'm The Munching Chef hehe
                                    </p>
                                </Typography>
                                <Typography align="center" variant="h5">
                                    Thank you for visiting :)
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default About;
