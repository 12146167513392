import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";

const sendMail = () => {
    const mailto = "mailto:themunchingchef@gmail.com?subject=Enquiry";
    window.location.href = mailto;
};

const Social = () => {
    return (
        <div>
            <Paper elevation={0} style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="column"
                    alignContent="justify"
                    style={{ padding: "20px" }}
                >
                    <Typography
                        variant="h2"
                        align="center"
                        style={{ fontWeight: "600" }}
                    >
                        Social Media
                    </Typography>
                    <Grid container justify="center">
                        <a
                            href="https://www.youtube.com/channel/UCwx2IUo1-0ZNsb49FVhumbw"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton aria-label="youtube">
                                <YouTubeIcon color="primary" fontSize="large" />
                            </IconButton>
                        </a>
                        <a
                            href="https://www.instagram.com/themunchingchef/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton aria-label="instagram">
                                <InstagramIcon
                                    color="primary"
                                    fontSize="large"
                                />
                            </IconButton>
                        </a>
                    </Grid>
                    <Typography
                        style={{ fontWeight: "600" }}
                        variant="h3"
                        align="center"
                    >
                        Contact
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        style={{ paddingBottom: "30px" }}
                    >
                        themunchingchef@gmail.com
                    </Typography>
                    <Button color="secondary" onClick={sendMail}>
                        <Typography variant="body1">Email me!</Typography>
                    </Button>
                </Grid>
            </Paper>
        </div>
    );
};

export default Social;
