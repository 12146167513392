import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRecipesThunk, deleteRecipe } from "../actions/recipeActions";

//Theme
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

//TABLES
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

class AdminRecipeList extends React.Component {
    state = {
        searchString: "",
        page: 0,
        rowsPerPage: 5,
    };

    componentDidMount() {
        if (!this.props.recipe) {
            this.props.getRecipesThunk();
        }
    }

    onSearchInputChange = (event) => {
        this.setState({
            searchString: event.target.value,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: parseInt(newPage) });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    render() {
        //console.log("recipes", this.props.recipes);
        //console.log("search", this.state.currentDisplay);

        //Admin status check
        if (!this.props.user.isAdmin) {
            return null;
        }

        let currentList = this.props.recipes;

        currentList = currentList.sort((a, b) => a.createdAt - b.createdAt);

        if (this.state.searchString !== "") {
            currentList = currentList.filter((recipe) => {
                const name = recipe.name.toLowerCase();
                const filter = this.state.searchString.toLowerCase();

                return name.includes(filter);
            });
        }

        return (
            <div style={{ minHeight: "75vh" }}>
                <Grid container justify="center">
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h6"
                            component="span"
                            align="center"
                        >
                            <h2>Admin Recipe Page</h2>
                        </Typography>
                        <Grid container justify="center">
                            <TextField
                                style={{ padding: 24 }}
                                id="searchInput"
                                placeholder="Search for Recipes"
                                margin="normal"
                                onChange={this.onSearchInputChange}
                            />
                        </Grid>
                        {(this.props.recipes && this.props.recipes.length) >
                        0 ? (
                            <Grid container justify="center">
                                <Paper>
                                    <TableContainer component={Paper}>
                                        <Table
                                            //className={classes.table}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow tabIndex={-1}>
                                                    <TableCell align="center">
                                                        Recipe Category
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Recipe Name
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Date Published
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Video Available
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Recipe Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(this.state.rowsPerPage > 0
                                                    ? currentList.slice(
                                                          this.state.page *
                                                              this.state
                                                                  .rowsPerPage,
                                                          this.state.page *
                                                              this.state
                                                                  .rowsPerPage +
                                                              this.state
                                                                  .rowsPerPage
                                                      )
                                                    : currentList
                                                ).map((recipe) => (
                                                    <TableRow key={recipe.id}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {recipe.category}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {recipe.name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {new Date(
                                                                recipe.createdAt
                                                                    .seconds *
                                                                    1000
                                                            ).toLocaleDateString(
                                                                "en-gb"
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {recipe.video ? (
                                                                <CheckIcon />
                                                            ) : (
                                                                <ClearIcon />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Link
                                                                to={`/admin/recipes/${recipe.id}`}
                                                                className="item"
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="edit"
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Link>
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => {
                                                                    if (
                                                                        window.confirm(
                                                                            "Are you sure you want to delete?"
                                                                        )
                                                                    ) {
                                                                        this.props.deleteRecipe(
                                                                            recipe
                                                                        );
                                                                        setTimeout(
                                                                            () => {
                                                                                window.location.reload(
                                                                                    false
                                                                                );
                                                                            },
                                                                            1000
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={currentList.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={
                                                this.handleChangeRowsPerPage
                                            }
                                        />
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        ) : null}
                        <br />
                        <Grid container justify="center">
                            <Button variant="contained" color="primary">
                                <Link
                                    to={`/admin/create-recipe`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Publish new recipe
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recipes: state.recipes,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecipesThunk: () => dispatch(getRecipesThunk()),
        deleteRecipe: (recipe) => dispatch(deleteRecipe(recipe)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRecipeList);
