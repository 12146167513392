import React from "react";
import { Link } from "react-router-dom";

import cookingbg from "../../images/cookingbg.jpg";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "90vh",
        paddingTop: "10px",
    },
    text: {
        width: "100%",
        color: "#FFFFFF",
        whiteSpace: "nowrap",
    },
    button: {
        //position: "relative",
        paddingRight: "20px",
        paddingLeft: "20px",
        minWidth: "200px",
        textTransform: "none",
    },
    subscribeButton: {
        paddingBottom: "1000px",
    },
    logo: {
        maxWidth: "200px",
        maxHeight: "200px",
        margin: "auto",
    },
}));

const Welcome = () => {
    const classes = useStyles();
    return (
        <section
            id="welcome"
            className={classes.root}
            style={{
                backgroundImage: "url(" + cookingbg + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            <Grid
                style={{ height: "95%", width: "100%" }}
                container
                spacing={2}
                direction="column"
                alignItem="center"
                justify="center"
            >
                <Grid item>
                    <Grid container justify="center" alignItems="center">
                        <img src="logo.png" alt="" className={classes.logo} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography
                        className={classes.text}
                        variant="h4"
                        align="center"
                        gutterBottom
                    >
                        Search, Cook, Share
                    </Typography>
                    <Typography className={classes.text} align="center">
                        Your go-to Cooking Platform
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="column"
                    >
                        <Link
                            to={`/recipes`}
                            className="item"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <Button
                                className={classes.button}
                                variant="contained"
                                filled
                                color="primary"
                            >
                                <Typography variant="body1">Recipes</Typography>
                            </Button>
                        </Link>

                        {/*  <div style={{ paddingTop: "20px" }}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                filled
                                color="primary"
                            >
                                <Link
                                    to={`/coffee`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    <Typography variant="body1">
                                        Coffee
                                    </Typography>
                                </Link>
                            </Button>
                        </div> */}
                        {/* 
                        <div style={{ paddingTop: "20px" }}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                filled
                                color="primary"
                            >
                                <Link
                                    to={`/recipes/submit`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Contribute Recipe!
                                </Link>
                            </Button>
                        </div>
                        */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                justify="flex-start"
                direction="column"
            >
                <a
                    href="https://www.youtube.com/channel/UCwx2IUo1-0ZNsb49FVhumbw?sub_confirmation=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "inherit",
                        textDecoration: "none",
                    }}
                >
                    <Button
                        className={classes.button}
                        variant="contained"
                        filled
                        color="secondary"
                    >
                        <Typography variant="body1">
                            Subscribe for YouTube updates
                        </Typography>
                    </Button>
                </a>
            </Grid>
        </section>
    );
};

export default Welcome;
