import React from "react";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button, Paper } from "@material-ui/core";

const AboutWidget = () => {
    return (
        <div>
            <Paper elevation={0} style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="column"
                    alignContent="justify"
                    style={{ padding: "20px" }}
                >
                    <Typography
                        variant="h2"
                        align="center"
                        style={{ fontWeight: "600" }}
                    >
                        About
                    </Typography>
                    <Typography variant="h6" align="center">
                        The Munching Chef
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{ padding: "15px" }}
                        align="center"
                    >
                        Hi, I'm Chris!{" "}
                        <p>
                            I love cooking and enjoying good food. My aim is to
                            share my love and passion for food and coffee with
                            all of you, so do subscribe and stay tuned for more!
                            :)
                        </p>
                    </Typography>
                    <Button color="secondary">
                        <Link
                            to={`/about`}
                            className="item"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <Typography variant="body1">Read more</Typography>
                        </Link>
                    </Button>
                </Grid>
            </Paper>
        </div>
    );
};

export default AboutWidget;
