import React from "react";
import { connect } from "react-redux";

import { getRecipesThunk } from "../actions/recipeActions";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";

import Welcome from "./Welcome";
import LatestRecipes from "./LatestRecipes";
import PopularRecipes from "./PopularRecipes";
import Social from "./Socials";
import AboutWidget from "./AboutWidget";

const useStyles = (theme) => ({});

class LandingPage extends React.Component {
    componentDidMount() {
        if (!this.props.recipe) {
            this.props.getRecipesThunk();
        }
    }

    render() {
        return (
            <div
                id="app"
                style={{
                    minHeight: "80vh",
                }}
            >
                <Welcome />
                {(this.props.recipes && this.props.recipes.length) > 0 ? (
                    <div>
                        <LatestRecipes recipes={this.props.recipes} />
                        <PopularRecipes recipes={this.props.recipes} />
                    </div>
                ) : (
                    <LinearProgress color="secondary" />
                )}
                <Divider />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    style={{ paddingTop: "40px", overflow: "hidden" }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        xl={3}
                        style={{ paddingRight: 20, paddingLeft: 20 }}
                    >
                        <AboutWidget />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        xl={3}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                        <div id="socialWidget">
                            <Social />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //console.log("state from list", state);
    return {
        recipes: state.recipes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecipesThunk: () => dispatch(getRecipesThunk()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(LandingPage));
