import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { MobileView, isDesktop } from "react-device-detect";

// Theme
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// Components
import Drawer from "./Drawer";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        margin: 0,
        color: "primary.light",
    },
    title: {
        flexGrow: 1,
        textAlign: "flex-start",
        paddingLeft: "20px",
        //display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    navoptions: {
        paddingLeft: "30px",
        paddingRight: "30px",
    },
    adminOptions: {
        marginLeft: theme.spacing(1),
    },
}));

function MainAppBar(props) {
    const classes = useStyles();
    const { auth } = props;
    const links = auth.uid ? (
        <SignedInLinks className={classes.adminOptions} />
    ) : (
        <SignedOutLinks className={classes.adminOptions} />
    );

    return (
        <div className={classes.root}>
            <AppBar
                className={classes.appbar}
                position="sticky"
                color="primary"
            >
                <Toolbar>
                    <MobileView>
                        <Drawer />
                    </MobileView>
                    <Grid
                        container
                        justify="flex-start"
                        style={{
                            paddingLeft: "60px",
                        }}
                    >
                        <Typography
                            className={classes.title}
                            variant="h5"
                            noWrap
                        >
                            <Link
                                to={`/`}
                                className="item"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                The Munching Chef
                            </Link>
                        </Typography>
                    </Grid>
                    {isDesktop ? (
                        <Grid container justify="flex-end">
                            <Typography className={classes.navoptions} noWrap>
                                <Link
                                    to={`/`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Home
                                </Link>
                            </Typography>
                            <Typography className={classes.navoptions} noWrap>
                                <Link
                                    to={`/about`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    About
                                </Link>
                            </Typography>
                            <Typography className={classes.navoptions} noWrap>
                                <Link
                                    to={`/recipes`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Recipes
                                </Link>
                            </Typography>
                            {/* <Typography className={classes.navoptions} noWrap>
                                <Link
                                    to={`/coffee`}
                                    className="item"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Coffee
                                </Link>
                            </Typography> */}
                            <Typography className={classes.navoptions} noWrap>
                                <HashLink
                                    smooth
                                    to={`/#socialWidget`}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    Contact
                                </HashLink>
                            </Typography>
                        </Grid>
                    ) : null}
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "0",
                            paddingLeft: "30px",
                        }}
                    >
                        {links}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    };
};

export default connect(mapStateToProps)(MainAppBar);
