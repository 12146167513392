import React from "react";
import { connect } from "react-redux";
import { getRecipesThunk } from "../actions/recipeActions";

//Theme
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import RecipeCard from "./RecipeCard";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = (theme) => ({
    root: {
        //margin: theme.spacing(1),
    },
    textField: {
        paddingLeft: 25,
        paddingRight: 10,
    },
    formControl: {
        paddingLeft: 20,
        minWidth: 120,
    },
});

class RecipeList extends React.Component {
    state = {
        searchString: "",
        sortMethod: "",
        page: 1,
        recipesPerPage: 8,
        selectedCategory: "",
    };

    componentDidMount() {
        if (!this.props.recipe) {
            this.props.getRecipesThunk();
        }
    }

    onSearchInputChange = (event) => {
        this.setState({
            searchString: event.target.value,
        });
    };

    onSortMethodChange = (event) => {
        this.setState({
            sortMethod: event.target.value,
        });
    };

    onCategoryChange = (event) => {
        this.setState({
            selectedCategory: event.target.value,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: parseInt(newPage) });
    };

    handleChangeRecipesPerPage = (event) => {
        this.setState({
            recipesPerPage: parseInt(event.target.value, 10),
            page: 1,
        });
    };

    render() {
        const { classes } = this.props;
        //console.log("props", this.props);

        let currentList = this.props.recipes;

        // FILTER BY CATEGORY
        if (
            this.state.selectedCategory !== "" &&
            this.state.selectedCategory !== "All"
        ) {
            currentList = currentList.filter((recipe) => {
                return recipe.category === this.state.selectedCategory;
            });
        } else if (this.state.selectedCategory === "All") {
            currentList = this.props.recipes;
        }

        // SORTING METHOD
        if (this.state.sortMethod === "name") {
            currentList = currentList.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
        } else if (this.state.sortMethod === "reverseName") {
            currentList = currentList
                .sort((a, b) => a.name.localeCompare(b.name))
                .reverse();
        } else if (this.state.sortMethod === "latest") {
            currentList = currentList.sort((a, b) => b.createdAt - a.createdAt);
        } else if (this.state.sortMethod === "earliest") {
            currentList = currentList.sort((a, b) => a.createdAt - b.createdAt);
        } else if (this.state.sortMethod === "mostPopular") {
            currentList = currentList.sort((a, b) => b.likeCount - a.likeCount);
        } else if (this.state.sortMethod === "leastPopular") {
            currentList = currentList.sort((a, b) => a.likeCount - b.likeCount);
        }

        // Search filter
        if (this.state.searchString !== "") {
            currentList = currentList.filter((recipe) => {
                const name = recipe.name.toLowerCase();
                const filter = this.state.searchString.toLowerCase();

                return name.includes(filter);
            });
        }

        return (
            <div style={{ minHeight: "75vh" }}>
                <div>
                    <form className={classes.root}>
                        <Grid container justify="center">
                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    htmlFor="category-native-simple"
                                    style={{ paddingLeft: 20 }}
                                >
                                    Category
                                </InputLabel>
                                <NativeSelect
                                    value={this.state.selectedCategory}
                                    onChange={this.onCategoryChange}
                                    inputProps={{
                                        name: "categoryMethod",
                                        id: "category-method-helper",
                                    }}
                                >
                                    <option value={"All"}>All</option>
                                    <option value={"Bread"}>Bread</option>
                                    <option value={"Dessert"}>Desserts</option>
                                    <option value={"Noodles"}>
                                        Noodle Dishes
                                    </option>
                                    <option value={"Pork"}>Pork Dishes</option>
                                    <option value={"Rice"}>Rice Dishes</option>
                                </NativeSelect>
                            </FormControl>
                            <TextField
                                className={classes.textField}
                                id="searchInput"
                                placeholder="Search for Recipes"
                                margin="normal"
                                onChange={this.onSearchInputChange}
                            />
                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    htmlFor="sort-native-simple"
                                    style={{ paddingLeft: 20 }}
                                >
                                    Sort By
                                </InputLabel>
                                <NativeSelect
                                    value={this.state.sortMethod}
                                    onChange={this.onSortMethodChange}
                                    inputProps={{
                                        name: "sortMethod",
                                        id: "sort-method-helper",
                                    }}
                                >
                                    <option value={"name"}>Name, A-Z</option>
                                    <option value={"reverseName"}>
                                        Name, Z-A
                                    </option>
                                    <option value={"latest"}>
                                        Time, Newest first
                                    </option>
                                    <option value={"earliest"}>
                                        Time, Oldest first
                                    </option>
                                    <option value={"mostPopular"}>
                                        Popularity, Highest first
                                    </option>
                                    <option value={"leastPopular"}>
                                        Popularity, Lowest first
                                    </option>
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    </form>
                </div>
                {(this.props.recipes && this.props.recipes.length) > 0 ? (
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: 24,
                            height: "100%",
                            width: "100%",
                        }}
                        alignItems="stretch"
                        display="flex"
                    >
                        {(this.state.recipesPerPage > 0
                            ? currentList.slice(
                                  (this.state.page - 1) *
                                      this.state.recipesPerPage,
                                  (this.state.page - 1) *
                                      this.state.recipesPerPage +
                                      this.state.recipesPerPage
                              )
                            : currentList
                        ).map((currentRecipe) => (
                            <Grid
                                key={currentRecipe.id}
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                            >
                                <RecipeCard recipe={currentRecipe} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <LinearProgress color="secondary" />
                )}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignContent="center"
                >
                    <FormControl className={classes.formControl}>
                        <NativeSelect
                            labelId="recipes-native-simple"
                            value={this.state.recipesPerPage}
                            onChange={this.handleChangeRecipesPerPage}
                            inputProps={{
                                name: "recipesPerPage",
                                id: "recipes-method-helper",
                            }}
                        >
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                        </NativeSelect>
                        <FormHelperText>Recipes per Page</FormHelperText>
                    </FormControl>
                    <Pagination
                        page={this.state.page}
                        count={2}
                        onChange={this.handleChangePage}
                        color="primary"
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //console.log("state from list", state);
    return {
        recipes: state.recipes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecipesThunk: () => dispatch(getRecipesThunk()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(RecipeList));
